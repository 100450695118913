.image-slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .slider-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .slider-image {
    width: 150px;
    height: 150px;
    margin: 5px;
    cursor: pointer;
  }
  
  .modal {
    display: block;
    position: fixed;
    z-index: 1;
    padding-top: 50px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  .image-slider-modal .modal-content {
    position: relative;
    margin: auto;
    width: 80%;
    max-width: 700px;
  }
  
.image-slider-modal .modal-content img {
    width: 100%;
    height: auto;
  }
  
  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
  }

  .pre_next_btn {
    position: absolute !important;
    z-index: 9999 !important;
    top: 50% !important;
    bottom: 50% !important;
    transform: translateY(-50%) !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 40px !important;
  }

  .pre_left {
    left: 0 !important;
  }

  .next_right {
    right: 0 !important;
  }
  