.main-content {
  padding-left: 26px;
}

.sb-avatar__image {
  object-fit: cover;
}

.mega-textarea::-webkit-scrollbar {
  display: none;
}

.mega-textarea {
  width: 88% !important;
}

.invert-scroll {
  direction: rtl;
    -webkit-transform: rotate(180deg);
}

.invert-scroll > * {
  direction: ltr;
  -webkit-transform: rotate(-180deg);
}

.chat-bodys {
  height: 300px;
  overflow: auto;
}

.left-user-chat-list {
  height: 100vh;
  overflow: auto !important;
}

.pac-container {
  background-color: inherit;
}

.pac-item, .pac-item-query {
  color: #686868;
}

.custom-notification-popup {
  width: 400px !important;
}

.custom-avatar-popup {
  margin-top: 85px !important;
}

.custom-group-detail-header {
  align-items: flex-end !important;
}

.dark .form-control:focus, .dark .form-select:focus {
  border-color: #2a2a2a !important;
}

.light .form-control:focus, .light .form-select:focus {
  border-color: #ced4da !important;
}

.dropdown-toggle::after {
  content: none !important;
}

.custom-image-height {
  height: 475px !important;
}

.divider {
  width: '100%';
  height: 0.5px;
  background-color: #68686853;
}

.custom-pogress .progress-bar {
  background-color: #58AC28 !important;
}

.custom-notification-count {
  width: 20px;
  height: 20px;
  right: 10px;
}

.custom-profile-page-header {
  height: 367px !important;
}

.custom-create-group-container {
  display: flex !important;
}

.header-for-bg .title-on-header {
  top: 35% !important;
}

.btn-close {
  filter: invert(1) !important;
}

::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

@media screen and (max-width: 500px) {
  .main-content {
    padding: 0 8px;
  }
  
  #rightSidebar {
    z-index: 999999;
  }
  
  .iq-search-bar, .custom-input-group {
    width: 100% !important;
  }
  
  .custom-avatar-popup {
    margin-top: 0 !important;
  }

  .custom-profile-avatar img {
    margin-top: 15px !important;
  }

  .mega-textarea {
    width: 65% !important;
  }

  .mega-textarea-container .form-group {
    margin-bottom: 0;
  }

  .custom-image-height {
    height: 150px !important;
  }

  .custom-avatar-dimension img, .custom-avatar-dimension .sb-avatar {
    width: 100px !important;
    height: 100px !important;
  }

  .custom-notification-count {
    width: 15px !important;
    height: 15px !important;
    right: 0 !important;
  }

  .custom-profile-page-header {
    height: 150px !important;
  }

  .custom-create-group-container {
    display: block !important;
  }

  .custom-group-icon-btn {
    justify-content: flex-end;
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .custom-post-footer-text {
    display: none;
  }

  .custom-notification-popup {
    width: 340px !important;
  }

  .top-nav-bar span {
    font-size: 32px;
  }
}
