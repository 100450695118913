.top-nav-bar {
    color: #CED4DA;
}

.top-nav-bar span {
    font-size: 40px;
}

.top-nav-bar.active {
    color: #50B5FF
}

a.top-nav-bar:hover,
.top-nav-notification-icon:hover {
    color: #243441 !important
}